import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import features from 'config/features';
import styles from './AddMatchedFunderPanel.module.scss';
import {
  getCompletedFundingForms,
  getFundingForms,
  getInsightsByType,
  getMatchesByType,
} from '_shared/api/fundingMatches';
import { ButtonStyleTypeEnum } from 'components/button/type';
import SortBar from 'components/sortBar';
import Spinner from 'components/spinner';
import Button from 'components/button';
import { SortOption } from 'components/sortBar/type';
import InsightCard from 'components/insightCard';
import { OfferTypes } from 'pages/deals/types';
import FundingMatchCard from 'pages/fundingMatches/components/fundingMatchCard/v2';
import NonSwoopEquityMessage from 'pages/fundingMatches/components/nonSwoopEquityMessage';

type Subtype = {
  id: string;
  label: string;
  name: string;
};

type AddFunderPanelProps = {
  companyId: string;
  inDealProductIds: string[];
  isSwoopGroupUser: boolean;
  readonly: boolean;
  processingProductId?: string;
  addDealLabel?: string;
  onAddToDeal: (opportunityId: string, opportunity: any) => void;
  onEditRequirements: (subtypeId: string) => void;
  onSubtypeIdChange?: (subtypeId: string) => void;
};

const NoMatchesScreen = ({ onEditRequirements }: { onEditRequirements: () => void }) => {
  return (
    <main className={styles['no-matches']}>
      <i className="material-icons">error</i>
      <span>No Matches Found</span>
      <Button buttonStyleType={ButtonStyleTypeEnum.SECONDARY} clickHandler={onEditRequirements}>
        Edit requirements
      </Button>
    </main>
  );
};

const LoadingScreen = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.loading}>
      <Spinner size="large" />
      <strong>{t('home:companydetails:fundingmatches:content:loading')}</strong>
      <div>{t('home:companydetails:fundingmatches:content:findingmatches')}</div>
    </div>
  );
};

const NonSwoopEquityScreen = () => {
  return (
    <div className={styles['non-swoop-equity']}>
      <NonSwoopEquityMessage customContainerClass={styles.message} />
    </div>
  );
};

const AddMatchedFunderPanel: React.FC<AddFunderPanelProps> = ({
  companyId,
  inDealProductIds,
  isSwoopGroupUser,
  readonly,
  processingProductId,
  addDealLabel,
  onAddToDeal,
  onEditRequirements,
  onSubtypeIdChange,
}) => {
  const [currentType, setCurrentType] = useState<OfferTypes>(OfferTypes.LOANS);
  const [currentSubtype, setCurrentSubtype] = useState<string>('');
  const [subtypesByType, setSubtypesByType] = useState<Record<OfferTypes, Subtype[]>>({
    [OfferTypes.LOANS]: [],
    [OfferTypes.EQUITY]: [],
    [OfferTypes.GRANTS]: [],
  });
  const [completedSubtypeIds, setCompletedSubtypeIds] = useState<string[]>([]);

  const [isFetchingProducts, setIsFetchingProducts] = useState(true);
  const [products, setProducts] = useState<any[]>([]);
  const [productTypeFilter, setProductTypeFilter] = useState<string>('');
  const [sortBy, setSortBy] = useState<SortOption | null>(null);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [insights, setInsights] = useState<any[]>([]);
  const shouldRenderNonSwoopMessage =
    !isSwoopGroupUser && !features.externalGroupEquityEnabled && currentType === OfferTypes.EQUITY;

  const fetchSubtypes = async () => {
    const { data } = await getFundingForms();

    const _subtypesByType = data.reduce((acc: any, { type, displayName, formId, name }: any) => {
      const _type =
        type === 'Loan' ? OfferTypes.LOANS : type === 'Equity' ? OfferTypes.EQUITY : 'Grants';

      acc[_type] = [...(acc[_type] ?? []), { label: displayName, id: formId, name }];
      return acc;
    }, {});

    setSubtypesByType({
      [OfferTypes.LOANS]: _subtypesByType[OfferTypes.LOANS] ?? [],
      [OfferTypes.EQUITY]: _subtypesByType[OfferTypes.EQUITY] ?? [],
      [OfferTypes.GRANTS]: _subtypesByType[OfferTypes.GRANTS] ?? [],
    });
  };

  const fetchCompletedSubtypes = async () => {
    const { data } = await getCompletedFundingForms(companyId);
    setCompletedSubtypeIds(data.map(({ formId }: any) => formId));
  };

  const sortedSubtypes = useMemo(() => {
    const subtypes = [...subtypesByType[currentType]];

    return subtypes.sort(({ id, label }) => {
      if (completedSubtypeIds.includes(id) && label !== 'Loans') return -1;
      else return 0;
    });
  }, [currentType, subtypesByType, completedSubtypeIds]);

  const availableProductTypes = useMemo(() => {
    return products.reduce<Array<{ label: string; value: string }>>(
      (acc, { subcategory, subcategoryName }: any) => {
        if (acc.some(({ value }) => value === subcategory)) return acc;
        acc.push({
          label: subcategoryName,
          value: subcategory,
        });
        return acc;
      },
      []
    );
  }, [products]);

  const sortedAndFilteredProducts = useMemo(() => {
    const filteredProducts = products.filter(({ subcategory }: any) => {
      return !productTypeFilter || subcategory === productTypeFilter;
    });

    if (!sortBy) return filteredProducts;

    return filteredProducts.sort((a, b) => {
      if (sortBy === 'amount') {
        return sortOrder === 'asc' ? b.minSize - a.minSize : a.minSize - b.minSize;
      } else if (sortBy === 'rate') {
        return sortOrder === 'asc' ? b.aprMin - a.aprMin : a.aprMin - b.aprMin;
      } else if (sortBy === 'decisionTime') {
        return sortOrder === 'asc' ? b.speed - a.speed : a.speed - b.speed;
      } else {
        return 0;
      }
    });
  }, [products, sortBy, sortOrder, productTypeFilter]);

  useEffect(() => {
    setSortBy(null);
    setSortOrder('asc');
    setProductTypeFilter('');

    if (!currentSubtype) {
      setCurrentSubtype(sortedSubtypes[0]?.name ?? '');
    }
  }, [currentType, currentSubtype, sortedSubtypes]);

  useEffect(() => {
    const fetchMatchesByType = async () => {
      try {
        setIsFetchingProducts(true);
        const {
          data: { opportunities = [] },
        } = await getMatchesByType(companyId, currentSubtype);

        setProducts(opportunities);
      } catch (e) {
        console.error(e);
      } finally {
        setIsFetchingProducts(false);
      }
    };

    const fetchInsightsByType = async () => {
      try {
        const { data } = await getInsightsByType(companyId, currentSubtype);
        // API returns an empty string if specified subtype is `findInvestor` rather than an empty array
        setInsights(data || []);
      } catch (e) {
        console.error(e);
      }
    };

    if (currentSubtype) {
      void fetchMatchesByType();
      void fetchInsightsByType();
    }
  }, [currentSubtype]);

  const onEditRequirementsClick = () => {
    const subtypeId = sortedSubtypes.find(({ name }) => name === currentSubtype)?.id;
    onEditRequirements(subtypeId!);
  };

  useEffect(() => {
    setCurrentSubtype(sortedSubtypes[0]?.name ?? '');
  }, [currentType, sortedSubtypes]);

  useEffect(() => {
    (async () => {
      await fetchCompletedSubtypes(); // Must be done before fetching subtypes because of sorting
      await fetchSubtypes();
    })();
  }, []);

  useEffect(() => {
    const subtypeId = sortedSubtypes.find(({ name }) => name === currentSubtype)?.id;

    onSubtypeIdChange?.(subtypeId ?? '');
  }, [currentSubtype]);

  const Content = () => {
    if (isFetchingProducts) {
      return <LoadingScreen />;
    }

    if (shouldRenderNonSwoopMessage) {
      return <NonSwoopEquityScreen />;
    }

    if (sortedAndFilteredProducts.length === 0) {
      return <NoMatchesScreen onEditRequirements={onEditRequirementsClick} />;
    }

    return (
      <div className={styles.content}>
        <div className={styles.insights}>
          {insights.map((insight) => (
            <InsightCard
              key={insight.id}
              insight={insight}
              currentSubtype={currentSubtype}
              companyId={companyId}
            />
          ))}
        </div>
        <SortBar
          productTypes={availableProductTypes}
          selectedProductType={productTypeFilter}
          onProductTypeChange={(e) => setProductTypeFilter(e.target.value)}
          selectedOfferType={currentType}
          sortBy={sortBy}
          sortOrder={sortOrder}
          onSortChange={(sortBy, sortOrder) => {
            setSortBy(sortBy);
            setSortOrder(sortOrder);
          }}
        />
        <div className={styles.results}>{sortedAndFilteredProducts.length} results</div>
        <div className={styles.products}>
          {sortedAndFilteredProducts.map((product) => (
            <FundingMatchCard
              key={product.opportunityId}
              opportunity={product}
              offerType={currentType}
              readonly={readonly}
              isInDeal={inDealProductIds.includes(product.opportunityId)}
              disabled={!!processingProductId}
              isLoading={processingProductId === product.opportunityId}
              onAddToDeal={onAddToDeal}
              addDealLabel={addDealLabel}
            />
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className={styles.panel}>
      <div className={styles.sidebar}>
        <div className={styles.types}>
          {features.loansEnabled && (
            <button
              className={cn({ [styles.active]: currentType === OfferTypes.LOANS })}
              onClick={() => setCurrentType(OfferTypes.LOANS)}
            >
              Loans
            </button>
          )}
          {features.equityEnabled && (
            <button
              className={cn({ [styles.active]: currentType === OfferTypes.EQUITY })}
              onClick={() => setCurrentType(OfferTypes.EQUITY)}
            >
              Equity
            </button>
          )}
          {features.grantsEnabled && (
            <button
              className={cn({ [styles.active]: currentType === OfferTypes.GRANTS })}
              onClick={() => setCurrentType(OfferTypes.GRANTS)}
            >
              Grants
            </button>
          )}
        </div>
        <div className={styles.subtypes}>
          {sortedSubtypes.map(({ id, label, name }) => (
            <button
              key={id}
              className={cn({ [styles.active]: currentSubtype === name })}
              onClick={() => setCurrentSubtype(name)}
            >
              {label}{' '}
              {completedSubtypeIds.includes(id) && (
                <i className={cn('material-icons')}>check_circle</i>
              )}
            </button>
          ))}
        </div>
      </div>
      <Content />
    </div>
  );
};

export default AddMatchedFunderPanel;
